import { FaCheck } from "react-icons/fa";

interface PropTypes {
    completed: boolean,
    id: string,
    labelText?: string,
    checked?: boolean,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export default function Checkbox({ completed, id, labelText, checked, onChange }: PropTypes) {
    return (
        <label htmlFor={id} className="w-full flex items-center gap-2 select-none justify-center">
            <input type="checkbox" className="hidden" id={id} onChange={onChange} checked={checked} />
            <div className={`w-5 h-5 border-2 border-primary rounded-md cursor-pointer ${completed ? "bg-primary" : "bg-transparent"}`}>
                <div className="flex justify-center items-center h-full">
                    {completed && <FaCheck className="text-white" size={12} />}
                </div>
            </div>
            <span className=" whitespace-nowrap w-max">
                {labelText}
            </span>
        </label>
    )
}
