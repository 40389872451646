"use client";
import { useState } from "react";
import { toast } from 'react-toastify';

export const useThrottler = () => {
  const [isTooManyRequests, setIsTooManyRequests] = useState<boolean>(false);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const rateLimit = (key: string, message: string, remainingTime: number) => {
    const throttlerMessage = `Previše zahtjeva poslato. Molimo vas da pokušate ponovo za ${formatTime(remainingTime)} minuta.`;
    setIsTooManyRequests(true);

    const localStorageRateLimitData = JSON.stringify({
      currentTime: new Date().getTime(),
      toastKey: message,
    });
    localStorage.setItem(key, localStorageRateLimitData);

    const intervalId = setInterval(() => {
      if (remainingTime > 0) {
        const formattedTime = formatTime(remainingTime);
        toast.update(message, {
          render: throttlerMessage + ` (${formattedTime})`,
          pauseOnHover: true,
          toastId: message,
          autoClose: false,
          closeButton: false,
        });
        remainingTime--;
        const rateLimitDataString = localStorage.getItem(key);
        let rateLimitParsedData;
        if (rateLimitDataString) {
          rateLimitParsedData = JSON.parse(rateLimitDataString);
        }
        const rateLimitData = JSON.stringify({
          remainingTime,
          currentTime: new Date(rateLimitParsedData.currentTime).getTime(),
          toastKey: message,
        });

        localStorage.setItem(key, rateLimitData);
      } else {
        clearInterval(intervalId);
        toast.dismiss(message);
        setIsTooManyRequests(false);
        localStorage.removeItem(key);
      }
    }, 1000);
  };

  return { isTooManyRequests, rateLimit };
};
