import { ILoggedInUser, IReservation } from "../../types";
import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";

interface CheckedAppointmentType {
  [key: string]: boolean;
}

type day = "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday";

interface FreeDay {
  id: string;
  from: string;
  to: string;
  reason: string;
  staffId: string;
  createdAt: string;
  updatedAt: string;
}

interface States {
  currentDate: Date;
  user: ILoggedInUser | undefined;
  role: {
    roleName: string;
    id: string;
  }; // Add role state
  isMobileMenuOpen: boolean;
  isCalendarOrTable: boolean;
  isModalOpen: boolean;
  isViewMore: boolean;
  checkedAppointments: CheckedAppointmentType;
  checkedPayment: CheckedAppointmentType;
  hideCompleted: boolean;
  updateHistory: string[];
  isAbsenceModalOpen: boolean;
  isCalendarModalOpen: boolean;
  isAppointmentModalOpen: boolean;
  toggleSavedChanges: boolean;
  searchValue: string;
  checkedPausesForDays: {
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
  };
  currentAppointment: IReservation;
  freeDays: FreeDay[];
  isDeletionToggled: boolean;
  clientId: string | null;
  appointmentNote: {
    appointmentId: string;
    note: string;
  } | null;
  absence: {
    staffId: string;
    from: string;
    to: string;
  } | null;
  isAppointmentCancelled: boolean; // Dodana varijabla za otkazivanje termina
  config: {
    [key: string]: {
      name: string,
      logo: string,
      singular: string,
      plural: string,
      whom: string,
      allowedDomains: string[]
    } 
  } | null;
}

interface Actions {
  nextDay: () => void;
  prevDay: () => void;
  setCurrentAppointment: (appointment: IReservation) => void;
  setUser: (user: ILoggedInUser) => void;
  setRole: (role: { roleName: string; id: string }) => void; // Add setRole action
  removeUser: () => void;
  toggleMobileMenu: () => void;
  toggleCalendarOrTable: () => void;
  toggleModal: () => void;
  toggleViewMoreMenu: () => void;
  setCheckedAppointments: (checkedAppointments: CheckedAppointmentType) => void;
  setCheckedPayment: (checkedPayment: CheckedAppointmentType) => void;
  setSearchValue: (value: string) => void;
  setHideCompleted: (state: boolean | ((prevState: boolean) => boolean)) => void;
  addUpdateHistory: (updatedAt: string) => void;
  toggleAbsenceModal: () => void;
  toggleCalendarModal: () => void;
  toggleAppointmentModal: () => void;
  toggleSavedChanges: (value: boolean) => void;
  togglePause: (day: day) => void;
  changeFreeDays: (freeDays: FreeDay[]) => void;
  toggleDeletion: () => void;
  setClientId: (clientId: string) => void;
  setAppointmentNote: (appointmentId:string, note: string) => void;
  addAbsence: (staffId: string, from: string, to: string) => void;
  addFreeDay: (freeDay: FreeDay) => void;
  resetCurrentDate: () => void;
  toggleAppointmentCancellation: () => void; // Dodana akcija za toggle otkazivanja termina
  setConfig: (config: any) => void, 
}

export const useGlobalStore = create<States & Actions>()(
  devtools(
    persist(
      // @ts-ignore
      (set) => ({
        currentDate: new Date(),

        appointment: undefined,
        user: undefined,
        // @ts-ignore
        role: {
          roleName: "",
          id: "",
        }, // Initialize role state
        isMobileMenuOpen: false,
        isCalendarOrTable: false,
        isModalOpen: false,
        isAbsenceModalOpen: false,
        isCalendarModalOpen: false,
        isViewMore: false,
        checkedAppointments: {},
        checkedPayment: {},
        hideCompleted: false,
        updateHistory: [],
        isAppointmentModalOpen: false,
        isSavedChanges: true,
        searchValue: "",
        checkedPausesForDays: {
          monday: true,
          tuesday: true,
          wednesday: true,
          thursday: true,
          friday: true,
          saturday: true,
          sunday: true,
        },
        freeDays: [],
        isDeletionToggled: false,
        clientId: null,
        appointmentNote: null,
        absence: null,
        config: null,
        togglePause(day) {
          set((state) => ({
            checkedPausesForDays: {
              ...state.checkedPausesForDays,
              [day]: !state.checkedPausesForDays[day],
            },
          }));
        },
        
        nextDay: () =>
          set((state) => {
            const newDate = new Date(state.currentDate);
            newDate.setDate(newDate.getDate() + 1);
            return { currentDate: newDate };
          }),
        prevDay: () =>
          set((state) => {
            const newDate = new Date(state.currentDate);
            newDate.setDate(newDate.getDate() - 1);
            return { currentDate: newDate };
          }),
        setCurrentAppointment: (currentAppointment) => set({ currentAppointment: currentAppointment }),
        setUser: (user) => set({ user: user }),
        setRole: (role) => set({ role: role }), // Implement setRole action
        setAppointmentNote: (appointmentId: string, note: string) => set({ appointmentNote: { appointmentId, note } }),
        removeUser: () => set({ user: undefined }),
        toggleMobileMenu: () => set((state) => ({ isMobileMenuOpen: !state.isMobileMenuOpen })),
        toggleCalendarOrTable: () => set((state) => ({ isCalendarOrTable: !state.isCalendarOrTable })),
        toggleAbsenceModal: () => set((state) => ({ isAbsenceModalOpen: !state.isAbsenceModalOpen })),
        toggleModal: () => set((state) => ({ isModalOpen: !state.isModalOpen })),
        // @ts-ignore
        toggleSavedChanges: (value: boolean) => set({ isSavedChanges: value }),
        toggleAppointmentModal: () => set((state) => ({ isAppointmentModalOpen: !state.isAppointmentModalOpen })),
        toggleViewMoreMenu: () => set((state) => ({ isViewMore: !state.isViewMore })),
        setCheckedAppointments: (
          checkedAppointments // Action to set checkedAppointments
        ) => set((_state) => ({ checkedAppointments: checkedAppointments })),
        setCheckedPayment: (
          checkedPayment // Action to set checkedAppointments
        ) => set((_state) => ({ checkedPayment: checkedPayment })),
        setHideCompleted: () => set((state) => ({ hideCompleted: !state.hideCompleted })),
        setSearchValue: (value: string) => set((_state) => ({ searchValue: value })),
        toggleDeletion: () => set((state) => ({ isDeletionToggled: !state.isDeletionToggled })), // Implement toggleDeletion
        addUpdateHistory: (updatedAt) =>
          set((state) => ({
            updateHistory: state.updateHistory.includes(updatedAt) ? state.updateHistory : [...state.updateHistory, updatedAt],
          })),
        changeFreeDays: (freeDays) =>
          set((state) => {
            // Ensure the state update is done in a controlled manner
            if (state.freeDays !== freeDays) {
              return { freeDays };
            }
            return state;
          }),
          setClientId: (clientId) => set({ clientId: clientId }),
          addAbsence: (staffId, from, to) => set({ absence: { staffId, from, to } }),
          addFreeDay: (freeDay) => set((state) => ({ freeDays: [...state.freeDays, freeDay] })),
          resetCurrentDate: () => set({ currentDate: new Date() }),
        isAppointmentCancelled: false, // Inicijalizacija varijable
        toggleAppointmentCancellation: () => set((state) => {
          return {
            isAppointmentCancelled: !state.isAppointmentCancelled,
            isModalOpen: !state.isModalOpen, // Uključivanje toggle za modal
          };
        }),
        setConfig: (config) => set((state) => ({ config })), 
      }),
      { name: "globalStore" }
    )
  )
);
