import * as React from 'react';
import { SVGProps } from 'react';
export default function ShowPasswordIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      {...props}
    >
      <g stroke="#6B7280" strokeWidth={0.625}>
        <path d="M9.688 8.5a1.688 1.688 0 1 1-3.376 0 1.688 1.688 0 0 1 3.375 0Z" />
        <path d="M8 12.063A3.567 3.567 0 0 0 11.562 8.5 3.562 3.562 0 1 0 8 12.063ZM12.352 4.8a8.033 8.033 0 0 1 2.825 3.639.188.188 0 0 1 0 .122A8.033 8.033 0 0 1 8 13.687 8.033 8.033 0 0 1 .823 8.561a.188.188 0 0 1 0-.122A8.033 8.033 0 0 1 8 3.313 8.033 8.033 0 0 1 12.352 4.8Z" />
      </g>
    </svg>
  );
}
