import * as React from 'react';
import { SVGProps } from 'react';
export default function MailIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      {...props}
    >
      <path
        stroke="#6B7280"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.334 3.167H2.667c-.736 0-1.333.597-1.333 1.333v8c0 .736.597 1.333 1.333 1.333h10.667c.736 0 1.333-.597 1.333-1.333v-8c0-.736-.597-1.333-1.333-1.333Z"
      />
      <path
        stroke="#6B7280"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m14.667 5.167-5.98 3.8a1.293 1.293 0 0 1-1.373 0l-5.98-3.8"
      />
    </svg>
  );
}
