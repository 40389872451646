'use client';
import { ValidationError } from '@tanstack/react-form';
import { ShowPasswordIcon } from '../../public/icons';
import React, { useState } from 'react';

interface IInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  label?: string;
  errorMessage?: string | ValidationError[];
  warningMessage?: string;
}

export const Input = (props: IInputProps): React.JSX.Element => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [inputType, setInputType] = useState<string>(props.type || 'text');
  const togglePasswordVisibility = () => {
    setInputType((prevType) => (prevType === 'password' ? 'text' : 'password'));
  };

  return (
    <div className="w-full">
      <div className="flex flex-col">
        <label className="text-[#1F2937]  tracking-[0.07px] capitalize text-sm font-semibold ml-1 pb-2.5">
          {props.label}
        </label>
        <div
          className={`flex items-center border bg-white ${props.errorMessage
            ? 'border-red-500 shadow-red-200'
            : props.warningMessage
              ? 'border-yellow-500 shadow-yellow-200'
              : isFocused
                ? 'border-primary shadow-primary/50'
                : 'border-[#E5E7EB]'
            } rounded-lg shadow px-3 py-2.5 space-x-4 transition-all duration-500 ease-linear`}
        >
          {props.leftIcon ? <>{props.leftIcon}</> : null}
          <input
            type={inputType}
            onChange={props.onChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => {
              setIsFocused(false);
            }}
            id={props.id}
            name={props.name}
            value={props.value}
            placeholder={props.placeholder}
            className=" placeholder:text-[#6B7280]  flex-1 placeholder:font-medium placeholder:tracking-[0.75px] placeholder:text-sm focus:outline-none text-sm text-[#6B7280] font-medium tracking-[0.75px]
             "
          />

          {props.type === 'password' && (
            <div
              className="flex items-center justify-end cursor-pointer"
              onClick={togglePasswordVisibility}
            >
              <ShowPasswordIcon />
            </div>
          )}
          {props.rightIcon ? props.rightIcon : null}
        </div>
        {props.errorMessage && (
          <div className="px-1">
            {props.errorMessage && (
              <ul
                className={`${props.errorMessage ? 'text-red-500' : 'text-yellow-500'
                  } text-xs font-medium tracking-[0.07px] capitalize list-disc  ml-4 mt-1`}
              >
                {props.errorMessage && Array.isArray(props.errorMessage) ? (
                  props.errorMessage.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))
                ) : (
                  <li>{props.errorMessage}</li>
                )}
              </ul>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
