import React from 'react';
import { cva } from 'class-variance-authority';
import LoadingSpinner from './LoadingSpinner';
interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  loading?: boolean;
  intent?: 'default' | 'primary' | 'secondary' | 'ghost';
  size?: 'sm' | 'lg';
  fullWidth?: boolean;
}

const buttonVariants = cva(
  'rounded font-semibold tracking-wider transition-all duration-300 ease-linear max-w-fit disabled:cursor-not-allowed',
  {
    variants: {
      intent: {
        default:
          'bg-[#F9F9FB] text-black hover:bg-[#efefef] disabled:bg-[#F9F9FB]/80 disabled:text-black/40',
        primary:
          'bg-primary text-gray-100 hover:bg-[#1c1e54] disabled:bg-primary/40',
        secondary:
          'bg-secondary text-gray-100 hover:bg-[#b8064a] disabled:bg-secondary/40',
        ghost: 'bg-transparent hover:bg-gray-100',
      },
      size: {
        sm: 'px-6 py-3',
        lg: ' px-24 py-4',
      },
      fullWidth: {
        true: 'w-full',
      },
      default: {
        intent: 'default',
        size: 'lg',
      },
    },
  }
);

export const Button = ({
  intent = 'default',
  size = 'lg',
  fullWidth = false,
  loading,

  ...props
}: IButtonProps): React.JSX.Element => {
  return (
    <div className="flex flex-col">
      <button
        {...props}
        className={buttonVariants({ intent, size, fullWidth })}
      >
        {loading ? <LoadingSpinner /> : props.title}
      </button>
    </div>
  );
};
