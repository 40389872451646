import * as React from 'react';
import { SVGProps } from 'react';
export default function PasswordIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      {...props}
    >
      <mask id="a" fill="#fff">
        <path
          fillRule="evenodd"
          d="M2.115 3.948c-.781.78-.781 2.038-.781 4.552s0 3.771.781 4.552c.781.781 2.038.781 4.552.781h2.667c2.514 0 3.771 0 4.552-.781.781-.78.781-2.038.781-4.552s0-3.771-.781-4.552c-.78-.781-2.038-.781-4.552-.781H6.667c-2.514 0-3.771 0-4.552.781Zm6.386 3.219a.5.5 0 0 0-1 0v.467l-.405-.233a.5.5 0 0 0-.5.865l.405.233-.405.234a.5.5 0 0 0 .5.867l.405-.234v.467a.5.5 0 0 0 1 0v-.466l.404.233a.5.5 0 1 0 .5-.867L9.001 8.5l.404-.233a.5.5 0 0 0-.5-.867l-.404.233v-.466Zm-4.012-.5a.5.5 0 0 1 .5.5v.466l.404-.233a.5.5 0 1 1 .5.867L5.49 8.5l.404.233a.5.5 0 0 1-.5.867l-.404-.233v.466a.5.5 0 0 1-1 0v-.467l-.405.233a.5.5 0 0 1-.5-.865l.405-.234-.405-.233a.5.5 0 0 1 .5-.867l.405.234v-.467a.5.5 0 0 1 .5-.5Zm7.524.5a.5.5 0 0 0-1 0v.467l-.405-.233a.5.5 0 0 0-.5.865l.405.233-.406.234a.5.5 0 0 0 .5.867l.406-.234v.467a.5.5 0 1 0 1 0v-.466l.404.233a.5.5 0 0 0 .5-.867l-.404-.233.404-.233a.5.5 0 0 0-.5-.867l-.404.233v-.466Z"
          clipRule="evenodd"
        />
      </mask>
      <path
        stroke="#6B7280"
        strokeWidth={1.667}
        d="M2.115 3.948c-.781.78-.781 2.038-.781 4.552s0 3.771.781 4.552c.781.781 2.038.781 4.552.781h2.667c2.514 0 3.771 0 4.552-.781.781-.78.781-2.038.781-4.552s0-3.771-.781-4.552c-.78-.781-2.038-.781-4.552-.781H6.667c-2.514 0-3.771 0-4.552.781Zm6.386 3.219a.5.5 0 0 0-1 0v.467l-.405-.233a.5.5 0 0 0-.5.865l.405.233-.405.234a.5.5 0 0 0 .5.867l.405-.234v.467a.5.5 0 0 0 1 0v-.466l.404.233a.5.5 0 1 0 .5-.867L9.001 8.5l.404-.233a.5.5 0 0 0-.5-.867l-.404.233v-.466Zm-4.012-.5a.5.5 0 0 1 .5.5v.466l.404-.233a.5.5 0 1 1 .5.867L5.49 8.5l.404.233a.5.5 0 0 1-.5.867l-.404-.233v.466a.5.5 0 0 1-1 0v-.467l-.405.233a.5.5 0 0 1-.5-.865l.405-.234-.405-.233a.5.5 0 0 1 .5-.867l.405.234v-.467a.5.5 0 0 1 .5-.5Zm7.524.5a.5.5 0 0 0-1 0v.467l-.405-.233a.5.5 0 0 0-.5.865l.405.233-.406.234a.5.5 0 0 0 .5.867l.406-.234v.467a.5.5 0 1 0 1 0v-.466l.404.233a.5.5 0 0 0 .5-.867l-.404-.233.404-.233a.5.5 0 0 0-.5-.867l-.404.233v-.466Z"
        clipRule="evenodd"
        mask="url(#a)"
      />
    </svg>
  );
}
